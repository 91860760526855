var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"tw-text-center"},[_c('i',{staticClass:"fal fa-spinner-third fa-spin tw-text-3xl"})]):_c('ul',{staticClass:"tw-ml-12 md:tw-m-4 tw-w-auto tw-border-l tw-border-dewaele tw-border-opacity-40 md:tw-border-0"},_vm._l((_vm.history),function(ref){
var year = ref.year;
var months = ref.months;
return _c('li',{key:year},[_c('h1',{staticClass:"\n        tw-px-2 tw-py-6 tw-my-8 md:tw-m-auto tw-w-20 tw-h-20\n        tw-rounded-full tw-bg-dewaele tw-text-white tw-text-center\n        tw-shadow-small-red tw--translate-x-1/2 md:tw-translate-x-0\n      "},[_vm._v(" "+_vm._s(year)+" ")]),_c('ul',_vm._l((months),function(ref,index){
var month = ref.month;
var tags = ref.tags;
return _c('li',{key:(year + "_" + month),staticClass:"md:tw-flex tw-flex-row odd:tw-flex-row-reverse"},[_c('div',{staticClass:"tw-w-1/2 tw-hidden md:tw-block"}),_c('div',{staticClass:"tw-border-l tw-border-dewaele tw-border-opacity-40"}),_c('div',{staticClass:"tw-mt-8 md:tw-w-1/2"},[_c('div',{class:[
              'tw-my-4 tw-flex tw-items-baseline',
              index % 2 === 0 ? ' md:tw-flex-row-reverse md:tw-text-right' : 'tw-flex-row'
            ]},[_c('div',{class:[
                'tw-w-4 tw-h-4 tw-rounded-full tw-bg-white tw-border-2 tw-border-dewaele tw--translate-x-1/2',
                { 'md:tw-translate-x-1/2' : index % 2 === 0 }
              ]}),_c('h2',{staticClass:"tw-my-0 tw-mx-2 tw-pb-2 tw-border-b tw-border-opacity-40 tw-border-dewaele tw-flex-grow"},[_vm._v(" "+_vm._s(_vm.getMonthName(month))+" ")])]),_c('ul',{staticClass:"tw-my-2 tw-mx-6 tw-grid tw-gap-4"},[_c('li',{class:['tag-container', { 'md:tw-justify-end' : index % 2 === 0 }]},_vm._l((tags.active_tags),function(tag){return _c('Tooltip',{key:("active_" + (tag.id)),scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{staticClass:"tw-text-center"},[(tag.updated_by)?_c('span',{staticClass:"tw-font-semibold tw-block"},[_vm._v(" "+_vm._s(tag.updated_by.first_name)+" "+_vm._s(tag.updated_by.last_name)+" ")]):_vm._e(),_c('span',{staticClass:"tw-block"},[_vm._v(_vm._s(_vm.formatDateTime(tag.started_on)))])])]},proxy:true}],null,true)},[_c('router-link',{staticClass:"tag tw-block",style:(("\n                    background: " + (tag.color) + ";\n                    color: " + (_vm.getTagTextColor(tag.color)) + ";\n                  ")),attrs:{"to":{ name: 'ContactTagDetails', params: { id: tag.tag_id } }}},[_vm._v(" "+_vm._s(tag.name)+" ")])],1)}),1),_c('li',{class:['tag-container', { 'md:tw-justify-end' : index % 2 === 0 }]},_vm._l((tags.inactive_tags),function(tag){return _c('Tooltip',{key:("inactive_" + (tag.id)),scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{staticClass:"tw-text-center"},[(tag.updated_by)?_c('span',{staticClass:"tw-font-semibold tw-block"},[_vm._v(" "+_vm._s(tag.updated_by.first_name)+" "+_vm._s(tag.updated_by.last_name)+" ")]):_vm._e(),_c('span',{staticClass:"tw-block"},[_vm._v(_vm._s(_vm.formatDateTime(tag.started_on)))]),_c('span',{staticClass:"tw-block"},[_vm._v(_vm._s(_vm.formatDateTime(tag.ended_on)))])])]},proxy:true}],null,true)},[_c('router-link',{staticClass:"tag tw-bg-gray-cc !tw-text-tg-color tw-block",attrs:{"to":{ name: 'ContactTagDetails', params: { id: tag.tag_id } }}},[_vm._v(" "+_vm._s(tag.name)+" ")])],1)}),1)])])])}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }