<template>
  <div v-if="loading" class="tw-text-center">
    <i
      class="fal fa-spinner-third fa-spin tw-text-3xl"
    />
  </div>
  <ul v-else class="tw-ml-12 md:tw-m-4 tw-w-auto tw-border-l tw-border-dewaele tw-border-opacity-40 md:tw-border-0">
    <li v-for="{ year, months } in history" :key="year">
      <h1
        class="
          tw-px-2 tw-py-6 tw-my-8 md:tw-m-auto tw-w-20 tw-h-20
          tw-rounded-full tw-bg-dewaele tw-text-white tw-text-center
          tw-shadow-small-red tw--translate-x-1/2 md:tw-translate-x-0
        "
      >
        {{ year }}
      </h1>
      <ul>
        <li
          v-for="({ month, tags }, index) in months"
          :key="`${year}_${month}`"
          class="md:tw-flex tw-flex-row odd:tw-flex-row-reverse"
        >
          <div class="tw-w-1/2 tw-hidden md:tw-block" />
          <div class="tw-border-l tw-border-dewaele tw-border-opacity-40" />
          <div class="tw-mt-8 md:tw-w-1/2">
            <!-- Inside the list item, there is no loop, hence we cannot use odd: or even: here, so we take help of index -->
            <div
              :class="[
                'tw-my-4 tw-flex tw-items-baseline',
                index % 2 === 0 ? ' md:tw-flex-row-reverse md:tw-text-right' : 'tw-flex-row'
              ]"
            >
              <div
                :class="[
                  'tw-w-4 tw-h-4 tw-rounded-full tw-bg-white tw-border-2 tw-border-dewaele tw--translate-x-1/2',
                  { 'md:tw-translate-x-1/2' : index % 2 === 0 }
                ]"
              />
              <h2 class="tw-my-0 tw-mx-2 tw-pb-2 tw-border-b tw-border-opacity-40 tw-border-dewaele tw-flex-grow">
                {{ getMonthName(month) }}
              </h2>
            </div>
            <ul class="tw-my-2 tw-mx-6 tw-grid tw-gap-4">
              <li :class="['tag-container', { 'md:tw-justify-end' : index % 2 === 0 }]">
                <Tooltip
                  v-for="tag in tags.active_tags"
                  :key="`active_${tag.id}`"
                >
                  <router-link
                    :to="{ name: 'ContactTagDetails', params: { id: tag.tag_id } }"
                    :style="`
                      background: ${tag.color};
                      color: ${getTagTextColor(tag.color)};
                    `"
                    class="tag tw-block"
                  >
                    {{ tag.name }}
                  </router-link>
                  <template #popper>
                    <div class="tw-text-center">
                      <span v-if="tag.updated_by" class="tw-font-semibold tw-block">
                        {{ tag.updated_by.first_name }} {{ tag.updated_by.last_name }}
                      </span>
                      <span class="tw-block">{{ formatDateTime(tag.started_on) }}</span>
                    </div>
                  </template>
                </Tooltip>
              </li>
              <li :class="['tag-container', { 'md:tw-justify-end' : index % 2 === 0 }]">
                <Tooltip
                  v-for="tag in tags.inactive_tags"
                  :key="`inactive_${tag.id}`"
                >
                  <router-link
                    :to="{ name: 'ContactTagDetails', params: { id: tag.tag_id } }"
                    class="tag tw-bg-gray-cc !tw-text-tg-color tw-block"
                  >
                    {{ tag.name }}
                  </router-link>
                  <template #popper>
                    <div class="tw-text-center">
                      <span v-if="tag.updated_by" class="tw-font-semibold tw-block">
                        {{ tag.updated_by.first_name }} {{ tag.updated_by.last_name }}
                      </span>
                      <span class="tw-block">{{ formatDateTime(tag.started_on) }}</span>
                      <span class="tw-block">{{ formatDateTime(tag.ended_on) }}</span>
                    </div>
                  </template>
                </Tooltip>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { getTagTextColor, formatDateNlBe, formatDateTime } from '@/utils/helpers'
import { getContactTagsHistory } from '@/services/contacts'
import { errorModal } from '@/modalMessages'

export default {
  name: 'ContactTagHistory',
  components: {
    Tooltip
  },
  data () {
    return {
      loading: true,
      history: {}
    }
  },
  computed: {
    contactId () {
      return this.$route.params.id
    }
  },
  created () {
    this.fetchContactTagHistory()
  },
  methods: {
    getTagTextColor,
    formatDateTime,
    getMonthName (month) {
      const date = new Date()
      date.setMonth(month - 1)
      return formatDateNlBe(date, 'MMMM')
    },
    async fetchContactTagHistory () {
      try {
        this.loading = true
        const response = await getContactTagsHistory(this.contactId)
        this.history = response.data
        this.loading = false
        return response
      } catch (error) {
        errorModal('Kan de geschiedenis niet laden, probeer het opnieuw.')
      }
    }
  }
}
</script>

<style scoped>
.tag-container {
  @apply tw-flex tw-flex-row tw-flex-wrap tw-gap-1 tw-content-start;
}
</style>
